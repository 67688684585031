<template>
  <PopUp :show="modal" @hideModal="hideModal" class="modal--xs" bgColor="rgba(0,0,0,0.4)">
    <ValidationObserver v-slot="{ invalid }" tag="div" class="tag" ref="observer">
      <header class="tag__header">
        <h3 class="tag__header_title">{{ tag.id ? "Edit" : "Add" }} Tag</h3>
        <p class="tag__header_description">
          This change will be applied to all <br />instances of this tag
        </p>
      </header>
      <section class="form">
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Tag"
              :required="true"
              :validateError="errors[0] || serverError"
              v-model="tag.title"
              :element="tag.title"
              @input="tag.title = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
      </section>
      <div class="tag__footer">
        <BaseButton
          class="button--sm button--green button--uppercase"
          text="Save Changes"
          :loading="changesLoading"
          :disabled="invalid"
          @click="saveTag"
        ></BaseButton>
      </div>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: { PopUp, ValidationObserver, ValidationProvider, BaseInput, BaseButton },
  data() {
    return {
      tag: {
        title: "",
      },
      changed: false,
    };
  },
  created() {
    if (this.item != null) {
      this.tag = JSON.parse(JSON.stringify(this.item));
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("tags", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
  },
  watch: {
    tag: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (oldVal.title != "") {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("tags", ["setError"]),
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid ? this.saveTag() : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    saveTag() {
      if (this.tag.id) {
        this.$emit("updateTag", { data: this.tag });
      } else {
        this.$emit("createTag", { data: this.tag });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tag {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    margin-bottom: 4rem;
    &_title {
      @include type($fs: 3.4rem, $lh: 1.23, $fw: 600);
      margin-bottom: 10px;
      text-align: center;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 26px;
      }
    }
    &_description {
      @include type($fs: 16px, $lh: 1.18, $fw: 500);
      text-align: center;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    .button {
      margin-left: 10px;
      @media only screen and (max-width: $xs) {
        margin: 20px 10px 0 0;
      }
    }
  }
}
</style>